import { useStaticQuery, graphql } from "gatsby";

export const  GetProjectsData = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpProject {
        edges {
          node {
            databaseId
            projectName
            projectIntroduction
            projectDescription
            imageAlt
            showcaseImages {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            websiteURL
            date
            challenge
            solution
            results
            projectType
            shortDescription
            slug
            technologiesData {
              nodes {
                id
                title
              }
            }
            additionalInfoLeftImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            additionalInfoBottom
            additionalInfoRightText
            testimonials {
              nodes {
                clientName
                clientDesignation
                clientTestimonial
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allWpProject.edges;
};
