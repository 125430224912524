import React from "react";
const Error = () => {
  return (
    <div>
      <div className="bg-img">
        <div class="container ">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
              <div
                style={{ "min-height": "100vh" }}
                class="caption center d-flex justify-content-center "
              >
                <h1 className="animate-charcter text-center my-auto">
                  404! Page not Found!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Error;
