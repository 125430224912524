import { useStaticQuery, graphql } from "gatsby";

export const GetTechnologies = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        services {
          edges {
            node {
              technologiesData {
                edges {
                  node {
                    technologiesForService {
                      edges {
                        node {
                          slug
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.wpgraphql.services.edges.map((edge) => {
    const technologiesData = edge.node.technologiesData.edges;
    const technologiesForService = technologiesData.map(
      (techEdge) => techEdge.node.technologiesForService.edges
    );
    const flattenedTechnologies = [].concat(...technologiesForService);
    return flattenedTechnologies;
  });
};
