import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
import Error from "components/Error/error";
import LoadingScreen from "components/Loading-Screen";

const ErrorPage = () => {
  return (
    <div>
      <LoadingScreen/>
      <Navbar />
      <ParticalsOverlay />
      <Error />
      <Footer />
    </div>
  );
};

export default ErrorPage;
