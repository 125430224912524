import { useStaticQuery, graphql } from "gatsby";

export const GetBlogPostData = () => {
  const data = useStaticQuery(graphql`
  query {
    allWpPost {
      edges {
        node {
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          slug
          databaseId
          title
          content
          date
          tags {
            nodes {
              name
              slug
            }
          }
          galleryImages
          comments {
            nodes {
              content
              author {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  `);
  return data.allWpPost.edges;
};
