import { useStaticQuery, graphql } from "gatsby";

export const GetServicesData = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpService {
        edges {
          node {
            title
            slug
            description
            altText
            serviceImage {
              localFile {
                childrenImageSharp {
                  gatsbyImageData
                }
              }
            }
            technologiesData {
              nodes {
                title
                slug
                icon
              }
            }
            subTitle
          }
        }
      }
    }
  `);
  return data.allWpService.edges;
};
